@charset "UTF-8";
.centercard {
  display: flex;
  height: 100vh;
  align-content: center;
  justify-content: center;
  margin: 8em auto;
  max-height: 300px;
}

.scroll {
  width: 300px;
  height: 300px;
  overflow: auto;
}

.titulo {
  text-align: center;
  font-size: 30px;
}

.login {
  font-size: 110%;
}

.button {
  display: flex;
  justify-content: flex-end;
  color: grey;
}

.top {
  padding-top: 30px;
}

.container {
  margin: 10px;
}

.zero-result {
  display: flex;
  font-style: italic;
  align-items: center;
  justify-content: center;
  border-left: 2px;
  border-right: 2px;
  border-bottom: 2px;
  border-top: 0;
  padding: 15px 15px;
  width: 87%;
  margin-right: 0.5%;
  margin-left: 10.5%;
}

.spacer {
  flex: 1 1 auto;
}

.button-container {
  display: flex;
  width: 100%;
}

.half-width-button {
  background-color: rgb(66, 46, 164);
  color: white;
  width: calc(50% - 10px); /* Ajusta el ancho para incluir el margen */
  height: 80px;
  margin: 0 5px; /* Añade un margen horizontal */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

::ng-deep .mat-tab-label.mat-tab-label-active {
  background-color: #87CEEB !important; /* Celeste */
  color: white !important; /* Color del texto */
  border-radius: 5px; /* Opcional, bordes redondeados */
  transition: background-color 0.3s ease-in-out;
}

.contenido {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.encabezado {
  background-color: rgb(224, 211, 173);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facturar {
  background-color: rgb(73, 73, 184);
  color: white;
  height: 40px;
  box-shadow: 0 2px 2px 1px grey;
  margin-left: 65.5%;
}

.cotizador {
  width: 30%;
  justify-content: center;
}

.mini-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-card .dashboard-card-content {
  color: #FFFFFF;
  position: relative;
  width: 280px;
  height: 129.8px;
  border-radius: 10%; /* Esto hace que la card sea una elipse */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgb(252, 251, 251); /* Agrega una sombra para mayor visibilidad */
  overflow: hidden;
}

/* Especifica diferentes colores y degradados para cada mini-card */
.mini-card:nth-child(1) .dashboard-card-content {
  background: linear-gradient(to right, #5383EC, rgb(234, 238, 249)); /* Degradado azul */
}

.mini-card:nth-child(2) .dashboard-card-content {
  background: linear-gradient(to right, #9C55EC, rgb(238, 177, 242)); /* Degradado púrpura */
}

.mini-card:nth-child(3) .dashboard-card-content {
  background: linear-gradient(to right, #111E5F, rgb(71, 64, 163)); /* Degradado azul oscuro */
}

.mini-card:nth-child(4) .dashboard-card-content {
  background: linear-gradient(to right, #F1911E, rgb(246, 218, 174)); /* Degradado naranja */
}

.dashboard-container {
  position: relative;
  padding: 20px;
}

.group-47 {
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.05));
}

.dashboard-container {
  position: relative;
  padding: 20px;
}

.group-47 {
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0px 24px 44px rgba(0, 0, 0, 0.05));
}

.frame-62, .frame-63, .frame-64, .frame-65 {
  position: relative;
  width: 310px;
  height: 123.62px;
  border-radius: 20px;
  overflow: hidden;
}

.rectangle-1352, .rectangle-1354, .rectangle-1355, .rectangle-1356 {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.rectangle-1352 {
  background: #5383EC;
}

.rectangle-1354 {
  background: #9C55EC;
}

.rectangle-1355 {
  background: #111E5F;
}

.rectangle-1356 {
  background: #F1911E;
}

.ellipse-4, .ellipse-5 {
  position: absolute;
  width: 482.22px;
  height: 187.83px;
  background: #FFFFFF;
  background-blend-mode: luminosity;
  mix-blend-mode: lighten;
  filter: blur(67px);
  transform: rotate(-17.73deg);
}

.ellipse-4 {
  opacity: 0.26;
}

.ellipse-5 {
  opacity: 0.56;
}

.fi-br-map-marker-plus, .fi-br-comment-alt, .fi-br-spinner-alt, .fi-br-trophy {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}